.container {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 463px;
    padding: 15px 20px 20px;
    opacity: 0;
    z-index: 2;
    transition: opacity 1s ease-out, bottom 0.25s ease-in-out;
    pointer-events: none;
}

:global(.player-8U) .container,
:global(.player-4U) .container {
    width: 400px;
}

.container.controlsVisible {
    bottom: 80px;
}

:global(.player-16U) .container.controlsVisible {
    bottom: 100px;
}

.container.fullscreen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
}

:global(.player-tiny) .container.fullscreen {
    padding: 10px 20px;
}

.container.fadeIn {
    opacity: 1;
    pointer-events: all;
}

.headerText {
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 350;
    color: #f4f4f4;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

:global(.player-tiny) .headerText {
    margin-bottom: 5px;
}

.row {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
}

.row:last-of-type {
    margin-bottom: 0;
}

.thumbnail {
    height: 100%;
    width: 220px;
    min-width: 220px;
    margin-right: 20px;
}

:global(.player-8U) .thumbnail {
    width: 150px;
    min-width: 150px;
}

:global(.player-4U) .thumbnail {
    width: 120px;
    min-width: 120px;
}

:global(.player-tiny) .thumbnail {
    width: 70px;
    min-width: 70px;
}

.textContainer {
    flex: 1 1 auto;
}

.videoTitle {
    flex: 1;
    font-family: 'Escrow Condensed', Georgia, serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    color: #fff;
}

:global(.player-tiny) .videoTitle {
    font-size: 16px;
    line-height: 19px;
}

.countdownText {
    font-family: 'Retina', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 350;
    line-height: 22px;
    color: #ccc;
    margin-top: 5px;
}
