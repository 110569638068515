.videoTitleContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.34) 39%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
    pointer-events: none;
}

.videoTitle {
    position: relative;
    margin: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 135%;
    width: calc(100% -40px);
    color: rgb(255, 255, 255);
    font-family: 'Escrow Condensed', Georgia, serif;
}

.verticalTitleContainer {
    bottom: 0;
    height: 100%;
    background: none;
    align-content: flex-end;
}

.verticalTitleContainer .videoTitle {
    background: none;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 100px 30px 75px 30px;
    height: auto;
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, rgba(10, 10, 10, 0.8) 30%, #0a0a0a 100%);
}

:global(.player-4U) .videoTitle {
    font-size: 22px;
}

:global(.player-8U) .videoTitle {
    font-size: 32px;
    line-height: 34px;
}

:global(.player-12U) .videoTitle,
:global(.player-16U) .videoTitle {
    font-size: 40px;
}
