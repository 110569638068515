.container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.midrollMarker {
    position: absolute;
    width: 4px !important;
    background: #ffcc00;
    top: 0;
    height: 100%;
}
