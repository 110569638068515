/* critical:start */
.videoThumbContent {
    height: 100%;
    width: 100%;
    font-family: 'Retina', Helvetica, sans-serif;
    color: #fff;
}

.videoHint {
    position: absolute;
    z-index: 10;
    height: 60px;
    width: 60px;
    left: 50px;
    bottom: 30px;
    padding: 0;
    transform: none;
    transition: all 0.15s;
    transition-timing-function: ease-out;
}
.videoHint svg {
    height: 60px !important;
    width: 60px !important;
}

:global(.player-4U) .videoHint svg,
:global(.player-tiny) .videoHint svg {
    height: 32px !important;
    width: 32px !important;
}

.videoThumbTextGroup {
    position: absolute;
    height: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    top: calc(100% - 100px);
    width: calc(100% - 180px);
    left: 125px;
    justify-content: center;
}

.videoThumbText {
    position: relative;
    z-index: 1;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    order: 2;
    padding: 0 !important;
}

.videoThumbCta {
    position: relative;
    z-index: 2;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    order: 1;
}

:global(.player-4U) .videoThumbCta,
:global(.player-tiny) .videoThumbCta {
    display: none;
}
/* critical:end */
