/* critical:start */
.gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.gradientTop {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 24%,
        rgba(0, 0, 0, 0) 29%,
        rgba(0, 0, 0, 0.8) 100%
    );
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.videoThumbContent {
    composes: videoThumbContent from './base.css';
}

.videoHint {
    position: absolute;
    padding: 0;
    bottom: 5px;
    left: 30px;
    height: 26px;
    width: 26px;
    top: auto;
    transform: translateY(-50%);
    transition: all 0.15s;
    transition-timing-function: ease-out;
    z-index: 1;
}

.videoThumbContent:hover .videoHint polygon {
    fill: #0080c3;
    transition: all 0.15s ease-out;
}

.videoThumbFlashLine {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 13px;
    height: 13px;
    letter-spacing: 0.8px;
    line-height: 13px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Retina Narrow', Retina, Arial, Helvetica, sans-serif;
}

.videoThumbCta {
    position: absolute;
    bottom: 22px;
    left: 66px;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    text-transform: uppercase;
    display: inline-block;
    font-family: 'Retina', Retina, Arial, Helvetica, sans-serif;
}

.videoThumbCta span {
    display: inline-block;
    width: 60px;
    text-align: left;
    color: #ccc;
    font-weight: 300;
}
/* critical:end */
