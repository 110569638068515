.container {
    height: 100%;
    width: 100%;
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
}

.loadingScreen {
    background: #000;
    height: 100%;
    width: 100%;
    color: #fff;
    position: absolute;
    z-index: 3;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loadingScreen span {
    font-size: 30px;
    font-family: 'Retina', Helvetica, sans-serif;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 10px;
    position: relative;
    margin-top: 30px;
}

:global(.player-16U) .loadingScreen span {
    font-size: 28px;
}
:global(.player-12U) .loadingScreen span {
    font-size: 19px;
}
:global(.player-8U) .loadingScreen span {
    font-size: 15px;
}

:global(.player-4U) .loadingScreen span,
:global(.player-tiny) .loadingScreen span {
    font-size: 10px;
}

.videoLoading {
    position: relative;
    top: auto;
}

.videoLoadingInner {
    /* Add loading animation styles here */
}
