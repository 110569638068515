.slide {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 466px;
    display: flex;
    align-items: center;
}

:global(.player-8U) .slide {
    width: 445px;
}

.slide.slideIn {
    animation: slideIn 0.3s linear;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.thumbnail {
    height: 100%;
    width: 106px;
    margin-right: 20px;
}

:global(.player-8U) .thumbnail {
    width: 96px;
}

.textContainer {
    flex: 1 1 auto;
}

.headerText {
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 350;
    color: #f4f4f4;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.videoTitle {
    font-family: 'Escrow Condensed', Georgia, serif;
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    color: #fff;
}

:global(.player-8U) .videoTitle {
    font-size: 18px;
    line-height: 19px;
}
