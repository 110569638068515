/* critical:start */
.gradient {
    composes: gradient from './mobile.css';
}

.gradientTop {
    composes: gradientTop from './mobile.css';
}

.videoThumbContent {
    composes: videoThumbContent from './base.css';
}

.videoHint {
    composes: videoHint from './mobile.css';
}

.videoThumbContent:hover .videoHint polygon {
    fill: #0080c3;
    transition: all 0.15s ease-out;
}

.videoThumbFlashLine {
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: 'Retina', Retina, Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    text-transform: none;
    text-align: left;
}

:global(.video-wrapper.player-12U) .videoThumbFlashLine,
:global(.video-wrapper.player-8U) .videoThumbFlashLine,
:global(.video-wrapper.player-4U) .videoThumbFlashLine,
:global(.video-wrapper.player-tiny) .videoThumbFlashLine {
    font-size: 16px;
    line-height: 22px;
}

.videoThumbCta {
    composes: videoThumbCta from './mobile.css';
}

.videoThumbCta span {
    color: #fff !important;
}
/* critical:end */
