.liveIndicatorWrapper {
    position: absolute;
    z-index: 3;
    top: 0;
    font-family: 'RetinaMedium', Helvetica, sans-serif;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    pointer-events: none;
    width: inherit;
}

.liveButton {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 25px;
    border-radius: 3px;
    overflow: hidden;
    background: #ba0000;
    color: #fff;
    line-height: 26px;
    padding: 0 10px;
}
