.adCirlce {
    top: 0;
    position: absolute;
    z-index: 1;
    margin-left: 20px;
    margin-top: 20px;
    height: 48px;
    width: 48px;
    font-weight: 300;
    pointer-events: none;
    filter: drop-shadow(0 1px 6px rgba(0, 0, 0, 0.5));
}

.adText,
.adContent {
    color: rgb(255, 255, 255);
    font-weight: 300;
    position: absolute;
    font-family: 'Retina Narrow';
    letter-spacing: 0px;
    text-shadow: 0 1px 6px rgb(0 0 0 / 50%);
    font-size: 20px;
    line-height: 27px;
    pointer-events: none;
    white-space: nowrap;
}

.adText {
    top: 11px;
    left: 0px;
    pointer-events: none;
    width: 48px;
    text-align: center;
}

.adContent {
    left: 56px;
    top: 11px;
}
