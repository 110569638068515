/* critical:start */
.vidThumb {
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: 6;
    background-color: #000;
    position: absolute;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
}

.videoThumbContent {
    color: #fff;
    font-family: 'Retina', Helvetica, sans-serif;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.videoHint {
    position: absolute;
    transition: all 0.15s;
    transition-timing-function: ease-out;
    z-index: 1;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.videoThumbContent:hover .videoHint polygon,
.videoThumbContent .videoHint:focus polygon {
    fill: #0080c3;
    transition: all 0.15s ease-out;
}

.videoThumbTextGroup {
    display: contents;
}

.videoHint svg {
    padding: 5px;
}

:global(.player-16U) .videoHint svg,
:global(.player-12U) .videoHint svg {
    width: 70px;
    height: 70px;
}

:global(.player-8U) .videoHint svg {
    width: 60px;
    height: 60px;
}

:global(.player-4U) .videoHint svg,
:global(.player-tiny) .videoHint svg {
    width: 50px;
    height: 50px;
}

.videoThumbImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    top: 0;
    position: absolute;
    left: 0;
}

.videoThumbFlashLine {
    color: #ffffff;
    font-size: 13px;
    height: 13px;
    letter-spacing: 0.8px;
    line-height: 13px;
    text-transform: uppercase;
    position: absolute;
    top: 20px;
    left: 20px;
    font-family: 'Retina Narrow', Retina, Arial, Helvetica, sans-serif;
}

.videoThumbText {
    box-sizing: border-box;
    bottom: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 45%;
    width: 100%;
    font-size: 22px;
    line-height: 28px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
}

:global(.player-4U) .videoThumbText,
:global(.player-tiny) .videoThumbText {
    padding-top: 10px;
}

.videoTitle {
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
}

.visuallyHidden {
    left: 65px;
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
    pointer-events: none;
}

.hide {
    display: none;
}
/* critical:end */

/* @media (min-aspect-ratio: 16/9) {
    .video-wrapper .vidThumb video {
        top: 0;
        height: auto;
        width: 100%;
    }
}
 @media (max-aspect-ratio: 16/9) {
    .video-wrapper .vidThumb video {
        top: 0;
        height: 100%;
        width: auto;
    }
} */
