.preview {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 7; /* just above the thumbnail so events work */
}

.preview video {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    top: 0; /* Article app makes this element have position: absolute */
    right: 0;
}

.preview video.show {
    opacity: 1;
}

.hoverText {
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    font-size: 14px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    z-index: 8;
    display: none;
    background-color: rgba(34, 34, 34, 0.75);
    padding: 10px;
}

:global(.player-tiny) .hoverText {
    padding: 5px;
    font-size: 12px;
}

.hoverText.show {
    display: block;
}
