.videoSkipIcon {
    pointer-events: none;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAAAAABjvpF0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAnRSTlMAAHaTzTgAAAACYktHRAD/h4/MvwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEFJREFUGNNjYKARmC+PYP//D2cghJEEEcIogjBhNEGIMIYgSBiL4P14DMH78RjaQUJoghAhFEGYEJIgQghJkB4AAG9cX5X8X+CRAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    display: inline-block;
    height: 24px;
    margin-left: 5px;
    vertical-align: middle;
    width: 20px;
    display: none;
}

.adSkip {
    font-family: 'Retina', Helvetica, sans-serif;
    background: rgba(0, 0, 0, 0.7) !important;
    bottom: 55px;
    display: block;
    right: 0px;
    position: absolute;
    width: 120px;
    overflow: hidden;
    border: #fff 1px solid !important;
    border-right: 0 !important;
    z-index: 2;
    color: #fff;
    font-size: 16px;
    transition: 0.2s;
    z-index: 6;
}
.adSkip:hover {
    text-decoration: underline;
    background: rgba(50, 50, 50, 1) !important;
}
.skipTxt {
    line-height: 35px;
    display: inline-block;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.videoSkipCountdown {
    display: inline-block;
    margin-left: 6px;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
