.loader {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    height: 60px;
    width: 60px;
    z-index: 5;
    pointer-events: none;
    transform-origin: 50% 50%;
    animation-name: loadspin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.loader.hide {
    display: none;
}

@-webkit-keyframes loadspin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes loadspin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
