.videoSavedQueueModalContainer {
    color: #fff;
    position: absolute;
    width: calc(100% - 40px);
    height: 50px;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.8);
    pointer-events: all;
    margin: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.videoSavedQueueModalText {
    font-family: 'Retina', Helvetica, sans-serif;
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    pointer-events: none;
}

.videoSavedQueueModalButton {
    cursor: pointer;
    pointer-events: all;
    font-family: 'Retina', Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 2px;
    border: 1px solid rgb(255, 255, 255);
    background: none;
    color: #fff;
    outline: none;
}
