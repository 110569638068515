.title {
    font-family: var(--font-family-retina);
    font-weight: var(--font-weight-medium);
    color: var(--primary-text-color);
    font-size: 12px;
    line-height: 16px;
    margin: 0 0 10px;
}

.controls {
    display: flex;
    align-items: center;
}

.time {
    font-family: var(--font-family-retina);
    font-weight: var(--font-weight-regular);
    color: var(--secondary-text-color);
    font-size: 10px;
    line-height: 10px;
    margin: 0;
    flex: 1;
    letter-spacing: 0.5px;
}

.btn {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 24px;
    color: #000;
}

.btn.playPause {
    margin-right: 10px;
}

@media screen and (max-width: 639px) {
    .tile {
        line-height: 12.8px;
        margin: 0 0 6px;
    }

    .time {
        font-size: 8px;
    }

    .btn.closeBtn {
        transform: scale(0.56);
    }
}
