.subscribeScreenWrapper {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 20;
    background: none;
    cursor: default;
    display: flex;
    flex-direction: column;
    font-family: 'Retina', Helvetica, sans-serif;
    text-align: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}

.subscribeBar {
    position: absolute;
    bottom: 0;
    display: flex;
    background-color: #fff;
    margin: 1em;
    width: calc(100% - 2em);
}

.subscribeMessage {
    background-color: #fff;
    margin: 0 20px;
    width: calc(100% - 40px);
    text-align: left;
}

.subscribeButtonWrapper {
    gap: 20px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
}

.subscribeScreenWrapperSmall .subscribeBar {
    flex-wrap: wrap;
}

.subscribeButtonWrapper a {
    width: 140px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    padding: 12px 0;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
}

.subscribeScreenWrapperSmall .subscribeButtonWrapper {
    padding: 0 0 10px 0;
    margin: 0 auto;
}

.subscribeScreenWrapperSmall .subscribeButtonWrapper a {
    width: 100px;
    padding: 12px 0;
}

.subscribeScreenWrapperSmall .subscribeMessage {
    margin: 0 5px;
    width: 100%;
    text-align: center;
}

.subscribeButtonWrapper > .buttonSubscribe {
    color: #fff;
    background-color: #0274b6;
    border-color: #0274b6;
}

.subscribeButtonWrapper > .buttonSignIn {
    color: #333;
    background-color: #fff;
    border-color: #222;
}
