.preview {
    color: #fff;
    background: #000;
    position: absolute;
    bottom: 100px;
    opacity: 1;
    transition: opacity 0.2s ease-in;
    overflow: hidden;
    border: #fff 2px solid;
    user-select: none;
    pointer-events: none;
}

.preview.hide {
    opacity: 0;
}

:global(.player-12U) .preview,
:global(.player-8U) .preview,
:global(.player-4U) .preview,
:global(.player-tiny) .preview {
    bottom: 80px;
}

.time {
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    text-shadow: 2px 5px 5px rgba(0, 0, 0, 1);
    font-size: 20px;
    height: 20px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    position: absolute;
    line-height: 20px;
    bottom: 6px;
    z-index: 1;
}

.img {
    left: 0px;
    position: absolute;
}
