/* critical:start */
.videoThumbContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    text-align: center;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.videoThumbContent img {
    position: absolute;
}

.videoHint {
    order: 3;
    position: relative;
    z-index: 10;
    height: 50px;
    width: 100%;
    top: auto;
    transform: none;
    margin-top: 10px;
    transition: all 0.15s;
    transition-timing-function: ease-out;
}

.gradient,
.gradientTop {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.videoThumbContent:hover .videoHint polygon {
    fill: #0080c3;
    transition: all 0.15s ease-out;
}

.videoHint svg {
    padding: 5px;
    height: 35px !important;
    width: 35px !important;
}

:global(.player-16U) .videoHint svg {
    height: 60px !important;
    width: 60px !important;
}

:global(.player-12U) .videoHint svg {
    height: 45px !important;
    width: 45px !important;
}

.videoThumbText {
    order: 2;
    position: relative;
    z-index: 1;
    padding: 0 20px;
}

:global(.player-16U) .videoThumbText,
:global(.player-12U) .videoThumbText {
    padding: 0 35px;
}

.videoTitle {
    font-weight: 700;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 39px;
    text-align: center;
    text-transform: uppercase;
    max-width: 100%;
    width: 100%;
    text-shadow: none;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
}

:global(.player-16U) .videoTitle,
:global(.player-12U) .videoTitle {
    font-size: 38px;
    line-height: 42px;
    margin: 0 25px;
}

:global(.player-8U) .videoTitle,
:global(.player-4U) .videoTitle,
:global(.player-tiny) .videoTitle {
    font-size: 22px;
    line-height: 26px;
}

.videoThumbCta {
    order: 1;
    z-index: 1;
    font-size: 15px;
    letter-spacing: 0;
    margin-bottom: 10px !important;
    text-transform: uppercase;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
}

:global(.player-4U) .videoThumbCta,
:global(.player-tiny) .videoThumbCta {
    display: none;
}
/* critical:end */
