.container {
    height: 100%;
    width: 100%;
    z-index: 4;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    visibility: hidden;
}

.container svg {
    visibility: inherit !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .container {
        pointer-events: none;
    }
}

.visible {
    visibility: visible;
}

.topBackground {
    position: absolute;
    pointer-events: none;
    background: linear-gradient(
        to top,
        rgba(34, 34, 34, 0) 0%,
        rgba(34, 34, 34, 0.11) 38%,
        rgba(34, 34, 34, 0.33) 100%
    );
    border-radius: 0px 0px 0px 0px;
    height: 132px;
    width: 100%;
}
