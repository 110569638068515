.clickForSoundWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 5;
    top: 20px;
    left: 20px;
    height: 25px;
    padding: 15px 12px;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    background: #fff !important;
    font-family: 'Retina', Helvetica, sans-serif;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.5px;
    transition: 0.2s;
    color: #000;
}

.clickForSoundWrapper svg {
    fill: #000;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.clickForSoundWrapperWithLive {
    transform: translateX(47px);
}

.clickForSoundWrapperWithAdvertisment {
    transform: translateY(65px);
}

@media (hover: none) {
    .clickForSoundWrapper:hover,
    .clickForSoundWrapper:hover svg {
        color: #0080c3;
        fill: #0080c3;
    }
}
