/* critical:start */
.videoThumbContent {
    composes: videoThumbContent from './base.css';
    font-family: 'Escrow Condensed', Georgia, serif !important;
    text-align: left;
}

.videoThumbText {
    composes: videoThumbText from './base.css';
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    padding-bottom: 75px;
    height: 100%;
    font-size: 28px;
    line-height: 32px;
    font-weight: 700;
    justify-content: flex-end;
}

.videoTitle {
    composes: videoTitle from './base.css';
    font-weight: 700;
    width: calc(100% - 40px);
    margin: 0 20px;
}

.videoHint {
    display: none;
}

.verticalPlayButton {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background: #fff;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
}

.verticalPlayButton span {
    color: #000;
    font-family: 'Retina', Helvetica, sans-serif;
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 0 5px;
}

.verticalPlayButtonIcon {
    height: 16px;
    width: 16px;
}

/* critical:end */
