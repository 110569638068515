.modal {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 85px;
    right: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

:global(.player-16U) .modal {
    height: 101px;
    width: 379px;
    right: 30px;
}

:global(.player-12U) .modal {
    height: 101px;
    width: 379px;
    right: 20px;
    bottom: 70px !important;
}

:global(.player-8U) .modal,
:global(.player-4U) .modal,
:global(.player-tiny) .modal {
    top: 0;
    right: 0;
}

.notification {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 33px;
}

.notificationText {
    color: #fff;
    font-size: 13px;
    font-family: 'Retina';
    letter-spacing: 0px;
    line-height: 22px;
}

.notification img {
    margin-right: 8px;
}

:global(.player-16U) .notification {
    width: 379px;
    right: 30px;
    bottom: 190px;
}

:global(.player-12U) .notification {
    width: 379px;
    right: 20px;
    bottom: 175px;
}

:global(.player-8U) .notification {
    top: 20%;
}

:global(.player-4U) .notification,
:global(.player-tiny) .notification {
    top: 12%;
}

.btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
}

:global(.player-16U) .btns,
:global(.player-12U) .btns {
    margin-top: 10px;
}

:global(.player-4U) .btns {
    gap: 0;
}

.btn {
    display: flex;
    justify-content: center;
    flex-basis: 86px;
}

.btn a {
    text-decoration: none;
}

.btn > * {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    font-family: 'Retina Narrow';
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.92px;
    line-height: 13px;
}

.btn img {
    margin-bottom: 10px;
}
