.countdownContainer {
    width: 100%;
    height: 100%;
    z-index: 6;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thumbnail {
    background-size: contain;
    filter: blur(30px);
    -webkit-filter: blur(30px);
    width: 100%;
    height: 100%;
    transform: scale(1.3);
    z-index: 6;
    position: absolute;
    top: 0;
    left: 0;
}

.countdown {
    position: relative;
    z-index: 10;
    height: 103px;
}

.countdownSquare {
    min-width: 58px;
    height: 72px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    display: inline-block;
    margin-right: 6px;
    margin-top: 17px;
}

.squareNumber {
    height: 41px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 43px;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 2.65px;
    line-height: initial;
    margin-top: 3px;
    padding-left: 3px;
}

.squareTime {
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 0.68px;
    margin-top: 8px;
}

.title {
    width: 100%;
    height: 14px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-family: 'Retina Narrow', 'Retina', Arial, Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 0.8px;
}
