.videoTitle {
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    color: #001e20;
    font-size: 1.625rem;
    line-height: 2.25rem;
    padding: 0.5rem 0.4rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
}
