.scrubBar {
    position: absolute;
    width: calc(100% - 40px);
    pointer-events: all;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;
}

.scrubBar:hover,
.scrubBar.mobile {
    transition: none;
}

.scrubBackground {
    position: absolute;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.2);
}

.scrubPadding {
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: -10px;
    z-index: 1;
}

.scrubProgress {
    position: absolute;
    height: 100%;
    left: 0;
    background: #fff;
    width: 0;
    height: 2px;
}

.dot {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: -8px;
    right: -5px;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    opacity: 0;
}

.scrubBar:hover .scrubProgress .dot,
.scrubBar.mobile .scrubProgress .dot {
    opacity: 1;
    top: -6px;
}

.scrubBar:hover .scrubPadding {
    height: 22px;
    bottom: -12px;
}

.scrubBar:hover .scrubLoaded,
.scrubBar:hover .scrubProgress,
.scrubBar:hover .scrubBackground {
    height: 4px;
}

.scrubLoaded {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);
    width: 0;
}

.adMarkerContainer {
    position: absolute;
    width: 100%;
    bottom: 5px;
    height: 100%;
}

.scrubBar :global(.ad-midroll-marker) {
    position: absolute;
    width: 4px !important;
    background: #ffcc00;
    top: 0;
    height: 100%;
}
