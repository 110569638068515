.modal {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(34, 34, 34, 0.75);
    z-index: 5;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.modal.fadeOut {
    pointer-events: none;
    opacity: 0;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 11px;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.closeBtn,
.closeBtn svg {
    width: 11px;
}
